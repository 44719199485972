import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var deDE = {
  otd: {
    routes: {
      title: 'OTD',
      desc: 'This area provides tools for reporting and managing OTD numbers.',
      e2ereport: {
        desc: 'Übermitteln Sie wöchentliche E2E OTD-Leistungsberichte.',
        title: 'E2E Report'
      },
      e2ereportmgmt: {
        desc: 'Verwalten Sie Hierarchien von E2E OTD-Leistungsberichten und deren Funktionen.',
        title: 'E2E Report Management'
      },
      e2eutilities: {
        desc: 'Dienstprogramme bezüglich E2E OTD-Reporting.',
        title: 'E2E Utilities'
      },
      rxreport: {
        desc: 'Senden Sie den wöchentlichen Betriebsberichts-Rx.',
        title: 'RX Report'
      }
    }
  }
};
var enGB = {
  otd: {
    routes: {
      title: 'OTD',
      desc: 'Tools for reporting and managing OTD numbers',
      e2ereport: {
        desc: 'Transmit weekly E2E OTD performance reports.',
        title: 'E2E Report'
      },
      e2ereportmgmt: {
        desc: 'Transmit weekly E2E OTD performance reports.',
        title: 'E2E Report Management'
      },
      e2eutilities: {
        desc: 'Transmit weekly E2E OTD performance reports.',
        title: 'E2E Utilities'
      },
      rxreport: {
        desc: 'Transmit weekly E2E OTD performance reports.',
        title: 'RX Report'
      }
    }
  }
};
var esES = {
  otd: {
    routes: {
      title: 'OTD',
      desc: 'Herramientas para informar y gestionar los números de OTD.',
      e2ereport: {
        desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
        title: 'Informe E2E'
      },
      e2ereportmgmt: {
        desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
        title: 'Gestión de informes E2E'
      },
      e2eutilities: {
        desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
        title: 'Servicios E2E'
      },
      rxreport: {
        desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
        title: 'Informe RX'
      }
    }
  }
};
const OTD_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { OTD_TRANSLATIONS };
